/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/

// Normalize
@import 'vendor/normalize';

// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';

// Additional Presets & Variables
@import 'presets/grid-settings';
@import 'presets/helper-classes';

// Vendor
// @import 'vendor/accessibility';
// @import 'vendor/accordions';
// @import 'vendor/columns';
// @import 'vendor/forms';
// @import 'vendor/modal';
// @import 'vendor/magnific-popup';
// @import 'vendor/responsive-videos';
// @import 'vendor/search-results';
// @import 'vendor/tables';
// @import 'vendor/tabs';
// @import 'vendor/alerts';
// @import 'vendor/speedbump';

// Layout
// @import 'layout/header';
// @import 'layout/content';
// @import 'layout/footer';
// @import 'layout/sidebar';

// Components
// @import 'components/search';
// @import 'components/accordions';
// @import 'components/branding';
// @import 'components/columns';
// @import 'components/forms';
// @import 'components/general';
// @import 'components/iebar';
// @import 'components/images';
// @import 'components/nav-global';
// @import 'components/nav-primary';
// @import 'components/nav-secondary';
// @import 'components/tabs';
// @import 'components/typography';
// @import 'components/dnn';

// Modules
// @import 'modules/blog';
// @import 'modules/events';
// @import 'modules/gallery';
// @import 'modules/locations';
// @import 'modules/news';
// @import 'modules/search-results';
// @import 'modules/videos';

html,
body {
  padding: 0;
  font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important;
  color: black !important;
  background: white !important;
}

body,
body.subpage {
  padding: 30px;
}

.skipcontent,
.nav-open,
.nav-close,
.nav-wrap,
.search-form,
.btn-store-locator,
.nav-global,
.banner-wrapper,
.content-form.zip,
footer.primary,
.subpage main:before,
.btn-print {
  display: none;
}

header.primary {
  display: block;
  position: relative;
  top: auto;
  left: auto;
  padding: 0;
  width: 100%;
  background: transparent;
  border: 0;
}

img {
  max-width: none !important;
}

.store-locator .result {
  page-break-inside: avoid;
  break-inside: avoid;
}

.branding {
  display: block;
  width: 100%;
  height: auto;

  .element-invisible {
    display: block;
    position: relative !important;
    overflow: visible;
    clip: unset;
    width: 100%;
    height: auto;
  }

  .logo {
    display: none;
  }

  h1,
  h2 {
    margin: 0 0 20px 0;
    @include fontsize(40 40);
  }
}

.page-title {
  display: block;
  position: relative;
  top: auto;
  margin-left: 0;
  padding: 0;
  width: 100%;
  height: auto;
  color: black;
}

.wrap-content {
  margin: 0;
  max-width: 100%;
}

.content {
  padding: 0;
}

// .btn-print {
//   display: none
// }

.store-nav {
  display: none;
}

.store-locator .results-container {
  display: block;
}

.store-locator .store-locator-map {
  display: block;
  order: 0;
  margin-bottom: 30px;
  width: 800px;
  height: 500px;

  .locator-map {
    display: block;
    width: 800px;
    height: 500px;
  }
}

.store-locator .store-locator-search:after {
  display: none;
}

.store-locator .store-locator-results {
  padding: 0;
  width: 100%;

  &:after {
    display: none;
  }

  .results-list {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // display: grid;
    // grid-template-columns: 50% 50%;
    // grid-column-gap: 4px;
    padding: 0;
    height: auto;
    overflow: visible;
  }

  input[type='hidden'] {
    display: none;
  }

  .result {
    display: inline-block;
    margin-bottom: 4px;
    width: calc(50% - 3px);

    [itemprop='name'] {
      margin-top: -35px;
      padding-left: 60px;
      padding-right: 60px;
      @include fontsize(20 24);
    }

    [itemprop='address'] {
      margin-bottom: 10px;

      br {
        display: none;
      }
    }

    .amenities,
    .fuels {
      padding-top: 10px;
      padding-bottom: 10px;

      h3 {
        margin-bottom: 8px;
        @include fontsize(18 20);
      }

      ul {
        margin-bottom: 0;
      }
    }
  }
}

.store-locator .result .buttons {
  display: none;
}

.store-locator .result .amenities ul li:before,
.store-locator .result .fuels ul li:before {
  background: url('../images/ICN-Checkmark-Checked.svg') center center / 16px 16px no-repeat;
}